// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gportal/api/user/v1/authentication.proto (package gportal.api.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum gportal.api.user.v1.AuthenticationType
 */
export enum AuthenticationType {
  /**
   * @generated from enum value: AUTHENTICATION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: AUTHENTICATION_TYPE_PASSWORD = 1;
   */
  PASSWORD = 1,

  /**
   * @generated from enum value: AUTHENTICATION_TYPE_FACEBOOK = 2;
   */
  FACEBOOK = 2,

  /**
   * @generated from enum value: AUTHENTICATION_TYPE_GOOGLE = 3;
   */
  GOOGLE = 3,

  /**
   * @generated from enum value: AUTHENTICATION_TYPE_MICROSOFT = 4;
   */
  MICROSOFT = 4,

  /**
   * @generated from enum value: AUTHENTICATION_TYPE_TWITCH = 5;
   */
  TWITCH = 5,

  /**
   * @generated from enum value: AUTHENTICATION_TYPE_DISCORD = 6;
   */
  DISCORD = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(AuthenticationType)
proto3.util.setEnumType(AuthenticationType, "gportal.api.user.v1.AuthenticationType", [
  { no: 0, name: "AUTHENTICATION_TYPE_UNSPECIFIED" },
  { no: 1, name: "AUTHENTICATION_TYPE_PASSWORD" },
  { no: 2, name: "AUTHENTICATION_TYPE_FACEBOOK" },
  { no: 3, name: "AUTHENTICATION_TYPE_GOOGLE" },
  { no: 4, name: "AUTHENTICATION_TYPE_MICROSOFT" },
  { no: 5, name: "AUTHENTICATION_TYPE_TWITCH" },
  { no: 6, name: "AUTHENTICATION_TYPE_DISCORD" },
]);

/**
 * @generated from enum gportal.api.user.v1.TwoFactorType
 */
export enum TwoFactorType {
  /**
   * @generated from enum value: TWO_FACTOR_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TWO_FACTOR_TYPE_TOTP = 1;
   */
  TOTP = 1,

  /**
   * @generated from enum value: TWO_FACTOR_TYPE_WEBAUTHN = 2;
   */
  WEBAUTHN = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(TwoFactorType)
proto3.util.setEnumType(TwoFactorType, "gportal.api.user.v1.TwoFactorType", [
  { no: 0, name: "TWO_FACTOR_TYPE_UNSPECIFIED" },
  { no: 1, name: "TWO_FACTOR_TYPE_TOTP" },
  { no: 2, name: "TWO_FACTOR_TYPE_WEBAUTHN" },
]);

