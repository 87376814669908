import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client/core';
import { provideApolloClient } from '@vue/apollo-composable';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const client = new ApolloClient({
    cache: new InMemoryCache({
      resultCaching: false,
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
    devtools: {
      enabled: process.env.NODE_ENV === 'development',
    },
  });

  const httpLink = createHttpLink({
    uri: process.client
      ? config.public.apollo.httpBrowserEndpoint
      : config.public.apollo.httpServerEndpoint,
  });

  client.setLink(from([httpLink]));

  provideApolloClient(client);
});
