import type {
  LatencyLocation,
  LatencyMeasurement,
  LatencyResult,
} from '~/store/types';

type LocationStoreState = {
  latencyMeasurements: LatencyMeasurement[];
  selectedLocation: {
    slug: string;
    orderUrl?: string;
  };
  locations: LatencyLocation[];
};

/**
 * NOTE: potentially deprecated, since it can be replaced with provide/inject in LandingPageWrapper.vue,
 * since it is single purpose and not used anywhere else.
 */
export const useLocationStore = defineStore('location', () => {
  const state = reactive<LocationStoreState>({
    latencyMeasurements: [] as LatencyMeasurement[],
    selectedLocation: {
      slug: undefined,
      orderUrl: undefined,
    },
    locations: [] as LatencyLocation[],
  });

  const setLocation = ({
    slug,
    orderUrl,
  }: {
    slug: string;
    orderUrl?: string;
  }) => {
    state.selectedLocation = {
      slug,
      orderUrl,
    };
  };

  const setLatencyResult = (result: LatencyMeasurement) => {
    state.latencyMeasurements = state.latencyMeasurements.filter(
      (measurement) => measurement.slug !== result.slug,
    );
    state.latencyMeasurements.push(result);
  };

  const runLatencyMeasurements = (locations: LatencyLocation[]) => {
    const promises: Promise<void>[] = [];

    for (const location of locations) {
      promises.push(
        $fetch<LatencyResult>(location.endpoint, {
          timeout: 1500,
        })
          .then((response) => {
            setLatencyResult({
              slug: location.slug,
              latency: response.latency_ms,
            });
            console.debug(
              `[Latency]: Location ${location.slug} has ${response.latency_ms}ms latency.`,
            );
          })
          .catch(() => {
            console.log(`[Latency]: Location ${location.slug} failed.`);
          }),
      );
    }

    // just obligatory wait for all latency measurements
    return Promise.allSettled(promises);
  };

  return {
    ...toRefs(state),
    runLatencyMeasurements,
    setLocation,
  };
});
